<template>
    <painel titulo="Convênios de Execução Direta - Parâmetros" icone="pi pi-cog" :refreshFunction="obterParametros" v-if="mostrarDetalhes">
        <div class="mb-4">
            <!-- <detalhe titulo="Código Unidade" :size="size">
                
            </detalhe> -->
            <detalhe titulo="Código do Movimento" :size="size">
                {{ parametros?.codMov }}
            </detalhe>
            <detalhe titulo="Unidade" :size="size"> {{ parametros?.nomeUnidade }} ({{ parametros?.codUnidade }}) </detalhe>
            <!-- <detalhe titulo="Código Projeto" :size="size">
                {{ parametros?.codProjeto }}
            </detalhe> -->
            <detalhe titulo="Projeto" :size="size"> {{ parametros?.nomeProjeto }} ({{ parametros?.codProjeto }}) </detalhe>
            <!-- <detalhe titulo="Código Ação" :size="size">
                {{ parametros?.codAcao }}
            </detalhe> -->
            <detalhe titulo="Ação" :size="size"> {{ parametros?.nomeAcao }} ({{ parametros?.codAcao }}) </detalhe>
        </div>
        <Button label="Atualizar" icon="pi pi-pencil" @click="toAtualizar()" v-if="$temAcessoView('CONVENIOS-PARAMETROS-01')" />
    </painel>
    <painel titulo="Arquivos XML" icone="pi pi-book" :refreshFunction="obterParametros" v-if="mostrarDetalhes">
        <div class="mb-4">
            <ul>
                <li class="flex flex-row" v-for="arquivo in arquivosXml" :key="arquivo">
                    <btn-inserir-documento :documento="{ codMov: parametros?.codMov, tipo: arquivo }"></btn-inserir-documento>
                    <btn-download-documento :documento="{ codMov: parametros?.codMov, tipo: arquivo }"> </btn-download-documento>
                    <div class="text-700 font-medium text-md">
                        {{ arquivo }}
                    </div>
                </li>
            </ul>
        </div>
    </painel>
    <router-view :parametros="parametros" @obterParametrosAtualizados="obterParametros()"></router-view>
</template>

<script>
import Services from './services';
import BtnInserirDocumento from './BtnInserirDocumento.vue';
import BtnDownloadDocumento from './BtnDownloadDocumento.vue';

export default {
    components: {
        BtnInserirDocumento,
        BtnDownloadDocumento,
    },

    data() {
        return {
            parametros: null,
            size: '150',
            arquivosXml: ['TMOV', 'TMOVCOMPL', 'TMOVHISTORICO', 'TITMMOV', 'TITMMOVRATCCU'],
        };
    },

    methods: {
        obterParametros() {
            this.$store.dispatch('addRequest');
            Services.obterParametros().then((response) => {
                if (response?.success) {
                    this.parametros = response.data;
                } else {
                    this.parametros = null;
                }
                this.$store.dispatch('removeRequest');
            });
        },

        toAtualizar() {
            this.$router.push({
                name: 'Convenios_Parametros_Atualizar',
            });
        },

        camposAutomaticos(tipo) {
            switch (tipo) {
                case 'TMOV':
                    return this.camposAutomaticosTMOV;
                case 'TMOVCOMPL':
                    return this.camposAutomaticosTMOVCOMPL;
                case 'TMOVHISTORICO':
                    return this.camposAutomaticosTMOVHISTORICO;
                case 'TITMMOV':
                    return this.camposAutomaticosTITMMOV;
                case 'TITMMOVRATCCU':
                    return this.camposAutomaticosTITMMOVRATCCU;
                default:
                    return [];
            }
        },
    },

    computed: {
        mostrarDetalhes() {
            return this.$route.name == 'Convenios_Parametros';
        },
    },

    mounted() {
        this.obterParametros();
    },
};
</script>
