<template>
    <font-awesome-icon icon="download" @click="downloadDocumento()" size="lg" class="p-text-primary mr-4 mb-2 icon-button" title="Download"/>
</template>

<script>
import Services from './services'

export default {
    props: {
        documento: {
            type: Object,
            default: () => ({})
        }
    },

    methods: {
        downloadDocumento() {
            this.$store.dispatch('addRequest')
            Services.obterDocumento(this.documento).then(
                response => {
                    if (response) {
                        if (response.type === 'application/json') {
                            this.$toast.error(
                                'Erro ao realizar download do arquivo!!!',
                                'ERROR',
                                this.$toastConfig.error
                            )
                        } else {
                            this.$download(response,`Movimento_${this.documento.tipo}_${this.documento.codMov.replace('.','-')}.xml`)
                        }
                    }
                    this.$store.dispatch('removeRequest')
                }
            )
        }
    }
}
</script>
