<template>
  <div class="float-left mr-3">
    <font-awesome-icon icon="upload" @click="abrirModal()" size="lg" style="color: rgba(255, 187, 0, 0.753)" title="Upload" class="icon-button" />
    <Dialog v-model:visible="mostrarModal" :style="{ width: '700px' }" :modal="true" class="file-dialog">
      <div class="file-dialog-content">
        <div class="file-dialog-header">
          <h5>Documento - <Badge severity="warning">{{ operacao }}</Badge></h5>
        </div>
        <div class="file-dialog-body">
          <div class="file-details">
            <div class="file-detail mt-3">
              <span>Movimento: <strong>{{ documento.codMov }}</strong></span>
            </div>
            <div class="file-detail mt-3">
              <span>Tipo: <strong>{{ documento.tipo }}</strong></span>
            </div>
          </div>
          <div class="file-upload-container">
            <div class="p-field">
              <label for="arquivo" >Arquivo</label>
              <FileUpload
                :customUpload="true"
                @uploader="arquivoSelecionado"
                v-model="arquivo"
                class="mt-3"
                mode="basic"
                :auto="true"
                :choose-label="arquivo ? arquivo.name : 'Selecione o arquivo...'"
                :choose-options="{ icon: 'pi pi-folder-open' }"
                accept=".xml"
              ></FileUpload>
            </div>
          </div>
        </div>
        <div class="file-dialog-footer">
          <Button variant="secondary" @click.stop="fecharModal()">Cancelar</Button>
          <Button variant="primary" @click.stop="confirmarSalvar()" class="ml-1">Salvar</Button>
        </div>
      </div>
    </Dialog>
  </div>
</template>

<style scoped>
.file-dialog {
  width: 400px;
}

.file-dialog-content {
  padding: 20px;
}

.file-dialog-header {
  margin-bottom: 20px;
}

.file-dialog-body {
  margin-bottom: 20px;
}

.file-details {
  margin-bottom: 10px;
}

.file-detail {
  margin-bottom: 5px;
}

.file-upload-container {
  display: flex;
  align-items: center;
}

.file-dialog-footer {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}
</style>

  
  <script>
  import Services from './services';
  
  export default {
    props: {
      documento: {
        type: Object,
        default: () => ({})
      }
    },
  
    data() {
      return {
        mostrarModal: false,
        operacao: 'UPLOAD',
        erros: [],
        arquivo: null
      };
    },
  
    methods: {
      abrirModal() {
        this.mostrarModal = true;
      },
  
      fecharModal() {
        this.mostrarModal = false;
        this.resetInserirDocumento();
      },
  
      resetInserirDocumento() {
        this.arquivo = null;
        this.erros = [];
      },
  
      confirmarSalvar() {
        this.$confirm.require({
            message: `Confirma o upload do documento no ${this.documento.tipo} para o movimento ${this.documento.codMov}?`,
            header: 'Confirmação',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.salvarDocumento();
            },
        });
      },
  
      salvarDocumento() {
        this.$store.dispatch('addRequest');
        let documentoDto = {
          codMov: this.documento.codMov,
          tipo: this.documento.tipo,
          arquivo: this.arquivo
        };
        Services.inserirDocumento(documentoDto).then(response => {
            if (response) {
              this.fecharModal();
              this.registroSalvoComSucesso();
            } else {
              this.erros = response.errors;
            }
          this.$store.dispatch('removeRequest');
        });
      },

      arquivoSelecionado(event) {
            this.arquivo = event.files[0];
        },
  
      registroSalvoComSucesso() {
        this.$toast.add({
          severity: 'success',
          summary: 'Upload realizado com sucesso!',
          life: 3000
        });
        this.fecharModal();
      }
    },
  };
  </script>
  